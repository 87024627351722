import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import { OdataItems } from '@/shared/model/OdataItems';
import costCentre, { CostCentre } from '@/shared/model/costCentre';
import costCentreImportSetting, { CostCentreImportSetting } from '@/shared/model/costCentreImportSetting';
import { Organisation } from '@/shared/model/organisation';
import ImportTableView from '@/views/settings/company/account-assignment/import-table/import-table.vue';

const name = 'cost-centers-view';
const logger = new Logger(name);
const costCentreModule = namespace('costCentre');
const costCentreImportSettingModule = namespace('costCentreImportSetting');
const organizationModule = namespace('organization');

@Component({ name: name, components: { ImportTableView } })
export default class CostCentersView extends Vue {
  @costCentreImportSettingModule.Action('updateChangeClearBeforeImport')
  private updateChangeClearBeforeImport!: any;
  @costCentreImportSettingModule.Action('getCostCentreImportSetting')
  private getCostCentreImportSetting!: any;
  @costCentreImportSettingModule.Getter('getCostCentreImportSetting')
  private costCentreImportSetting!: any;
  @costCentreImportSettingModule.Getter('getInProgressUpdateCostCentreImportSetting')
  private inProgressUpdateCostCentreImportSetting!: boolean;
  @costCentreImportSettingModule.Getter('getCostCentreImportSettingsIsLoading')
  private costCentreImportSettingsIsLoading!: boolean;
  @costCentreModule.Action('uploadCostCentre')
  private uploadCostCentre!: any;

  @costCentreModule.Action('getCostCentres')
  private getCostCentres!: any;
  @costCentreModule.Getter('getCostCentres')
  private costCentres!: OdataItems<CostCentre>;
  @costCentreModule.Getter('getIsImporting')
  private isImporting!: boolean;

  files = [];

  get headers() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('column0'), value: 'value' },
      { text: this.$t('column1'), value: 'name' },
    ];
    return headers;
  }

  async mounted() {}
}
